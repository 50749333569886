var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { Emit } from "vue-property-decorator";
import { PageService } from "~/utils/page.service";
import { State } from "vuex-class";
import { SortService } from "~/utils/sort.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import ImportCaseCommon from "~/components/case-manage/import-case-common.vue";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import { DataImportService } from "~/services/dataimp-service/data-import.service";
var LeaveCaseImportList = /** @class */ (function (_super) {
    __extends(LeaveCaseImportList, _super);
    function LeaveCaseImportList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // 导入类型
        _this.templateType = "LEAVE_IMPORT";
        _this.searchModel = {
            rincipalId: "",
            batchNumber: "",
            status: "",
            type: _this.templateType //导入类型
        };
        /**弹窗 */
        _this.dialog = {
            importCase: false,
            importCaseDetail: false
        };
        _this.dataSet = [];
        _this.batchNumber = "";
        _this.loading = {
            state: false
        };
        return _this;
    }
    LeaveCaseImportList.prototype.mounted = function () {
        var _this = this;
        this.refreshData();
        // 收到消息通知时更新未读消息
        this.$reminder.addImportListener().subscribe(function (data) {
            if (data === "IMPORT_LEFT_CASE" || data === "IMPORT_LEFT_CONFIRMED") {
                var self = _this;
                setTimeout(function () {
                    self.refreshData();
                }, 2000);
            }
        });
    };
    LeaveCaseImportList.prototype.refreshData = function () {
        var _this = this;
        this.dataImportService
            .findAllDataImportResult(this.searchModel, this.pageService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    /**
     * 案件导入
     */
    LeaveCaseImportList.prototype.caseImportDialogOpen = function () {
        this.dialog.importCase = true;
    };
    /**
     * 错误信息下载
     */
    LeaveCaseImportList.prototype.errorTipsDownload = function (row) {
        var _this = this;
        this.uploadFileService
            .getFileStreamById(row.fileId)
            .subscribe(function () { return _this.$message.success("下载成功"); });
    };
    /**
     * 取消导入
     */
    LeaveCaseImportList.prototype.cancelImport = function (data) {
        var _this = this;
        this.$confirm("\u60A8\u786E\u5B9A\u8981\u53D6\u6D88\u5BFC\u5165" + data.batchNumber + "\u6279\u6B21\u7684\u7559\u6848\u6848\u4EF6\u5417\uFF1F", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.loading.state = true;
            _this.dataImportService
                .cancelImportLeave(data.id, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("取消导入成功!");
                _this.refreshData();
            });
        })
            .catch(function (ex) { });
    };
    /**
     * 留案案件撤销
     */
    LeaveCaseImportList.prototype.revertCick = function (row) {
        var _this = this;
        this.$confirm("确认要对此批次留案案件进行撤销", "提示").then(function () {
            _this.loading.state = true;
            _this.dataImportService
                .cancelBatchLeaveImport(row.id, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.refreshData();
            });
        });
    };
    /**
     * 确认导入
     */
    LeaveCaseImportList.prototype.confirmImport = function (data) {
        var _this = this;
        this.$confirm("您确定要导入此留案案件吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function () {
            _this.loading.state = true;
            _this.dataImportService
                .confirmImportLeave(data.id, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("导入成功!"), _this.refreshData();
            });
        });
    };
    /**
     * 查看详情
     */
    LeaveCaseImportList.prototype.viewDetail = function (rowData) { };
    __decorate([
        Dependencies(PageService)
    ], LeaveCaseImportList.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], LeaveCaseImportList.prototype, "sortService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], LeaveCaseImportList.prototype, "uploadFileService", void 0);
    __decorate([
        Dependencies(DataImportService)
    ], LeaveCaseImportList.prototype, "dataImportService", void 0);
    __decorate([
        State
    ], LeaveCaseImportList.prototype, "principalList", void 0);
    __decorate([
        Emit("onClickCaseDetail")
    ], LeaveCaseImportList.prototype, "viewDetail", null);
    LeaveCaseImportList = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                ImportCaseCommon: ImportCaseCommon
            }
        })
    ], LeaveCaseImportList);
    return LeaveCaseImportList;
}(Vue));
export default LeaveCaseImportList;
